<!-- 班级管理 -->
<template>
  <div class="history_integral_page">
    <el-select style="margin-bottom:0.5rem" v-model="id" placeholder="请选择" @change="change">
      <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.id">
      </el-option>
    </el-select>
    <div class="pointTit"><span class="text">我的历史积分</span></div>

    <div class="pointTab">
      <div class="head">{{tabSelect.title}}幼儿园总积分({{tabSelect.totalIntegral}})</div>
      <div class="cells">
        <div class="cell">
          <div>幼儿园积分</div>
          <div>{{tabSelect.schoolIntegral}}</div>
        </div>
        <div class="cell">
          <div>老师总积分</div>
          <div>{{tabSelect.teacherIntegral}}</div>
        </div>
        <div class="cell">
          <div>学生总积分</div>
          <div>{{tabSelect.studentIntegral}}</div>
        </div>
      </div>
      <div class="desc">
        <div class="tit">积分的作用</div>
        <div class="intro">
          <div>1. 申请足球特色幼儿园的重要评判标准之一。</div>
          <div>2. 可参加线下嘉年华活动，可兑换奖品，具体形式以网站通告为准。</div>
          <div>3. 在prefootball体系中，可参加学校，老师和孩子的评比。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'historyIntegral',
    components: {},
    data() {
      return {
        options: [],
        id: '',
        tabSelect: {}
      }
    },
    created() {},
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.getInfo()
      },
      getInfo() {
        this.api.admin.findListBySchoolId({ shcoolId: this.$store.state.userInfo.schoolId }).then(res => {
          this.options = res.data.map(item => {
            return {
              ...item,
              label: item.title + '积分概览'
            }
          })
          this.id = res.data[0].id
          this.tabSelect = res.data[0]
        })
      },
      change(val) {
        this.options.forEach(item => {
          if (item.id === val) {
            this.tabSelect = item
          }
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .history_integral_page {
    .pointTit {
      height: 80px;
      line-height: 80px;
      font-weight: 700;
      color: #cb1b31;
      font-size: 30px;
      box-sizing: border-box;
      text-align: center;
      background: #f5d1d6;
    }

    .pointTab {
      margin: 50px 230px;
      /* width: 8rem; */
      text-align: center;
      color: #cb1b31;
      font-size: 18px;
      line-height: 30px;
    }

    .pointTab .head {
      border: solid 2px #cb1b31;
      border-bottom: none;
      line-height: 75px;
      font-size: 24px;
      font-weight: bold;
    }

    .pointTab .cells {
      display: table;
      border-collapse: collapse;
      width: 100%;
      box-sizing: border-box;
    }

    .pointTab .cells .cell {
      display: table-cell;
      border: solid 2px #cb1b31;
      line-height: 40px;
      font-size: 20px;
      padding-top: 20px;
    }

    .pointTab .cells .cell .link {
      height: 20px;
      line-height: 20px;
      background: #228cec;
      color: #fff;
      display: inline-block;
      font-size: 24px;
      font-weight: bold;
      padding: 20px 40px;
      margin: 20px auto;
    }

    .pointTab .desc {
      text-align: left;
      display: table;
      width: 100%;
      font-size: 18px;
      margin-top: 20px;
      color: #228cec;
    }

    .pointTab .desc .tit {
      display: table-cell;
      width: 100px;
    }

    .pointTab .desc .intro {
      display: table-cell;
    }
  }
</style>